<template>
  <DataTable v-if="created" :options="options" />
</template>

<script>
import { FeatureMixin } from '@tutti/mixins';
import { VENUE_STATUSES, VENUE_VISIBILITIES } from '@tutti/constants';

export default {
  mixins: [FeatureMixin],
  data() {
    return {
      created: false,
      options: {
        export: true,
        action: {
          child: 'booking',
          showChildList: true,
          showPreview: true,
          showDuplicate: true,
          showHistory: true,
          downloadMedia: true,
          showDeleteVenue: true,
        },
        actions: [
          {
            text: 'Open',
            body: { status: 'open' },
          },
          {
            text: 'Close',
            body: { status: 'closed' },
          },
          {
            text: 'Verify',
            body: { isVerified: true },
          },
          {
            text: 'Unverify',
            body: { isVerified: false },
          },
          {
            text: 'Add in Sitemap',
            body: { sitemap: true },
          },
          {
            text: 'Remove from Sitemap',
            body: { sitemap: false },
          },
        ],
        headers: [
          {
            text: 'Name',
            value: 'name',
            filter: true,
            capitalize: true,
            component: { name: 'dtView', props: { name: 'space', permission: 'space' } },
          },
          {
            text: 'Location',
            value: 'location',
            filter: true,
            component: { name: 'dtView', props: { name: 'location', permission: 'location' } },
          },
          {
            text: 'Host profile',
            value: 'account',
            filter: true,
            component: { name: 'dtView', props: { name: 'account', permission: 'account' } },
          },
          {
            text: 'Status',
            value: 'status',
            width: '150px',
            component: { name: 'dtVenueStatus' },
            filter: {
              type: 'autocomplete',
              items: VENUE_STATUSES,
            },
          },
          {
            text: 'Visibility',
            value: 'visibility',
            width: '150px',
            capitalize: true,
            component: { name: 'dtChip' },
            filter: {
              type: 'autocomplete',
              items: VENUE_VISIBILITIES,
            },
          },
          {
            text: 'Verified',
            value: 'isVerified',
            width: '150px',
            align: 'center',
            component: { name: 'dtCheckbox', props: { action: true } },
          },
          {
            text: 'Featured',
            value: 'isFeatured',
            width: '150px',
            align: 'center',
            component: { name: 'dtCheckbox', props: { action: true } },
          },
          {
            text: 'Sitemap',
            value: 'sitemap',
            width: '120px',
            align: 'center',
            component: { name: 'dtCheckbox', props: { action: true } },
          },
        ],
        filters: [],
      },
    };
  },
  created() {
    this.$setFeatures({
      disciplines: true,
      activities: true,
      spaceTypes: true,
      locationTypes: true,
    });

    this.options.actions.push(
      {
        text: 'Feature',
        body: { isFeatured: true },
      },
      {
        text: 'Unfeature',
        body: { isFeatured: false },
      }
    );

    this.options.filters = [
      {
        label: 'Disciplines',
        items: this.disciplines,
        itemText: 'name',
        itemValue: '_id',
        key: 'disciplines.attributeId',
      },
      {
        label: 'Activities',
        items: this.activities,
        itemText: 'name',
        itemValue: '_id',
        key: 'activities.attributeId',
      },
      {
        label: 'Space types',
        items: this.spaceTypes,
        itemText: 'name',
        itemValue: '_id',
        key: 'types.attributeId',
      },
      {
        label: 'Location types',
        items: this.locationTypes,
        itemText: 'name',
        itemValue: '_id',
        key: 'locationTypes.attributeId',
      },
    ];

    this.created = true;
  },
};
</script>
